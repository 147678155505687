import _ from '@/utils/lodash'

export default {
  async selectTecnicos (Vue, idparteTrabajo) {
    const tecnicosAsignados = await Vue.$offline.parteTrabajoTecnico.selectTecnicosAsignados(idparteTrabajo)
    const notIn = _.map(tecnicosAsignados, 'tecnico.idtecnico')
    const tables = Vue.$offline.db.tables
    return await Vue.$offline.db
      .select()
      .from(tables.tecnico)
      .innerJoin(
        tables.empleado,
        tables.empleado.idempleado.eq(tables.tecnico.idempleado)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.tecnico.estado.gt(0),
          Vue.$offline.db.op.not(
            tables.tecnico.idtecnico.in(notIn)
          ),
        )
      )
      .exec()
  },
}
