<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron técnicos asignados"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :toolbar-options="toolbarOptions"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="parte_trabajo_tecnico.idparte_trabajo_tecnico"
      @click-toolbar-option="clickToolbarOption"
    >
      <template
        #listItem="slotProps"
      >
        <parte-trabajo-tecnico-list-item
          :item="slotProps.item"
        />
      </template>
    </b10-list>
    <parte-trabajo-tecnico-list-legend
      :showing.sync="showingDialogs.legends"
    />
    <b10-fab-button
      v-if="hasPerm(permissions.partes.annadirTecnico)"
      @click="clickAdd"
    />
    <parte-trabajo-tecnico-add-form
      v-if="dbReady"
      :key="parteTrabajoTecnicoAddFormKey"
      :showing.sync="showingDialogs.parteTrabajoTecnicoAddForm"
      :idparte-trabajo="routeParams.idparte_trabajo"
      @add-tecnico="addTecnico"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './ParteTrabajoTecnicoListData'
import ParteTrabajoTecnicoListItem from './components/ParteTrabajoTecnicoListItem'
import ParteTrabajoTecnicoListLegend from './components/ParteTrabajoTecnicoListLegend'
import ParteTrabajoTecnicoAddForm from './components/ParteTrabajoTecnicoAddForm'
import { get } from 'vuex-pathify'
import { doSync } from '@/sync/upload'

const pageStoreName = 'pagesOfflineParteTrabajoTecnicoList'

export default {
  components: {
    ParteTrabajoTecnicoListItem,
    ParteTrabajoTecnicoListLegend,
    ParteTrabajoTecnicoAddForm,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info'
        },
      },
      showingDialogs: {
        legends: false,
        parteTrabajoTecnicoAddForm: false,
      },
      parteTrabajoTecnicoAddFormKey: 0,
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    syncing: get('sync/syncing'),
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = `Técnicos asignados parte Nº${this.routeParams.idparte_trabajo}`
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectParteTrabajoTecnico(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.idparte_trabajo
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        await this.reloadItemsBase(
          await Data.selectParteTrabajoTecnicoRows(this, dirty.modified),
          dirty,
          'parte_trabajo_tecnico.idparte_trabajo_tecnico'
        )
      } finally {
        this.loadingData = false
      }
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      }
    },
    async clickAdd () {
      this.showingDialogs.parteTrabajoTecnicoAddForm = true
    },
    async sincronizar () {
      if (!this.syncing) {
        try {
          await doSync(this.$offline, this.$api, this.$loading, this.$alert)
        } finally {
          await this.loadPage()
        }
      } else {
        this.$alert.showSnackbarInfo('Ya está sincronizando los cambios')
      }
    },
    async forceRenderParteTrabajoTecnicoAddForm () {
      // https://michaelnthiessen.com/force-re-render
      this.parteTrabajoTecnicoAddFormKey += 1
    },
    async addTecnico (data) {
      await Data.insert(this, data.formData, this.routeParams.idparte_trabajo)
      const res = await this.$alert.showConfirm(
        `Se ha añadido al técnico "${data.formData.tecnico.nombre_y_apellido}" al parte, ¿desea sincronizar los datos para que pueda verlo?`
      )
      if (res) {
        await this.sincronizar()
      }
      // Recargo de nuevo la página para mostrar el resultado final.
      this.forceRenderParteTrabajoTecnicoAddForm()
      this.resetList()
    }
  }
}
</script>
