import { likeRegExp } from '@/offline/database'
import { v4 as uuidv4 } from 'uuid'

export default {
  async _addSubqueriesParteTrabajoTecnico (Vue, rows) {
    const tables = Vue.$offline.db.tables
    for (let row of rows) {
      row.conTiempoTrabajado = (await Vue.$offline.db
        .select()
        .from(tables.tiempo_trabajado)
        .where(
          Vue.$offline.db.op.and(
            tables.tiempo_trabajado.idparte_trabajo.eq(row.parte_trabajo_tecnico.idparte_trabajo),
            tables.tiempo_trabajado.idempleado.eq(row.empleado.idempleado),
          )
        )
        .exec()).length > 0
    }
    return rows
  },
  _selectParteTrabajoTecnicoBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.parte_trabajo_tecnico)
      .innerJoin(
        tables.tecnico,
        tables.parte_trabajo_tecnico.idtecnico.eq(tables.tecnico.idtecnico)
      )
      .innerJoin(
        tables.empleado,
        tables.tecnico.idempleado.eq(tables.empleado.idempleado)
      )
  },
  async selectParteTrabajoTecnico (Vue, filter, search, sorter, page, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.empleado.nombre_y_apellido.match(reQ),
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.parte_trabajo_tecnico.idparte_trabajo.eq(idparteTrabajo),
        ...whereSearch,
        ...whereFilter,
      )
    )
    const rows = await this._selectParteTrabajoTecnicoBase(Vue)
      .where(...where)
      .limit(Vue.$offline.db.ITEMS_PER_PAGE)
      .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
      .orderBy(tables.empleado.nombre_y_apellido)
      .exec()
    return [
      await this._addSubqueriesParteTrabajoTecnico(Vue, rows),
      []
    ]
  },
  async selectParteTrabajoTecnicoRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    const rows = await this._selectParteTrabajoTecnicoBase(Vue)
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo_tecnico.idparte_trabajo_tecnico.in(pks),
        )
      )
      .exec()
    return (await this._addSubqueriesParteTrabajoTecnico(Vue, rows))
  },
  async insert (Vue, formData, idparteTrabajo) {
    await Vue.$offline.parteTrabajoTecnico.insertSync({
      idparte_trabajo: idparteTrabajo,
      idparte_trabajo_tecnico: uuidv4(),
      idarticulo: formData.tecnico.idarticulo,
      idtecnico: formData.tecnico.idtecnico,
      // TODO: añadir el check de principal al form
      principal: false,
      es_desplazamiento: false,
    })
  },
}
