<template>
  <v-dialog
    v-model="internalShowing"
    max-width="400px"
  >
    <v-card>
      <v-card-text class="pt-4">
        <v-form v-model="formValid">
          <b10-dynamic-form
            v-model="formModel"
            :schema="formSchema"
            :options="formOptions"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click.stop="clickCancelar"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          @click.stop="clickAceptar"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import internalShowingMixin from "@/mixins/internalShowingMixin";
import Data from './ParteTrabajoTecnicoAddFormData'
import _ from '@/utils/lodash'

export default {
  mixins: [internalShowingMixin],
  props: {
    idparteTrabajo: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      formValid: false,
      formModel: {
        tecnico: '',
      },
      formSchema: {
        type: "object",
        properties: {
          tecnico: {
            type: "object",
            title: "Técnico",
            "x-fromData": "context.tecnicos",
            "x-itemKey": "idtecnico",
            "x-itemTitle": "nombre_y_apellido",
          },
        },
      },
      formOptions: {
        locale: "es",
        context: {
          tecnicos: []
        },
      },
    };
  },
  async created () {
    const data = await Data.selectTecnicos(this, this.idparteTrabajo)
    this.formOptions.context.tecnicos = _.map(data, item => ({
      idtecnico: item.tecnico && item.tecnico.idtecnico || null,
      nombre_y_apellido: item.empleado && item.empleado.nombre_y_apellido || null,
      idarticulo: item.tecnico && item.tecnico.idarticulo || null,
    }))
  },
  methods: {
    clickAceptar() {
      this.$emit("add-tecnico", {
        idtecnico: this.formModel.tecnico.idtecnico,
        formData: this.formModel,
      });
      this.internalShowing = false;
    },
    clickCancelar() {
      this.internalShowing = false;
    },
  },
};
</script>
